'use client';

import I18nProvider from 'domains/i18n/provider';
import React from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import sassBreakpoints from 'src/assets/scss/breakpoints.module.scss';
import 'src/assets/scss/global.scss';
import { ErrorBoundary } from 'src/features/shared/components/error-boundary';
import { TooltipProvider } from 'src/features/shared/components/tooltip/Tooltip';
import { BreakpointsContext } from 'utils/hooks/use-breakpoints';
import { LangContextProvider } from 'utils/hooks/use-lang';

const breakpoints = Object.entries(sassBreakpoints).reduce(
  (breakpoints, [name, value]) => {
    return {
      ...breakpoints,
      [name]: {
        name,
        value: parseInt(value),
      },
    };
  },
  {},
);

const queryClient = new QueryClient();

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <ErrorBoundary>
      <I18nProvider>
        <LangContextProvider>
          <QueryClientProvider client={queryClient}>
            <Hydrate>
              <BreakpointsContext.Provider value={breakpoints}>
                <TooltipProvider>{children}</TooltipProvider>
              </BreakpointsContext.Provider>
            </Hydrate>
          </QueryClientProvider>
        </LangContextProvider>
      </I18nProvider>
    </ErrorBoundary>
  );
}
