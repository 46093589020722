import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/config/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/din-next-lt-pro/din-next-lt-pro-regular.woff2\",\"weight\":\"400\"},{\"path\":\"../assets/fonts/din-next-lt-pro/din-next-lt-pro-medium.woff2\",\"weight\":\"500\"},{\"path\":\"../assets/fonts/din-next-lt-pro/din-next-lt-pro-bold.woff2\",\"weight\":\"700\"},{\"path\":\"../assets/fonts/din-next-lt-pro/din-next-lt-pro-heavy.woff2\",\"weight\":\"800\"}],\"display\":\"swap\",\"variable\":\"--font-din\"}],\"variableName\":\"dinNext\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/config/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/hackney/hackney-vector.woff2\"}],\"variable\":\"--font-hackney\",\"display\":\"block\"}],\"variableName\":\"hackney\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/features/shared/components/draft-mode/components/DraftModeToolbar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/features/shared/components/global-styles/GlobalStyles.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/features/shared/components/providers/Providers.tsx");
