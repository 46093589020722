'use client';
import 'src/assets/scss/global.scss';
import { dinNext, hackney } from 'src/config/fonts';

export function GlobalStyles() {
  return (
    <style jsx global>{`
      :root {
        --font-din: ${dinNext.style.fontFamily};
        --font-hackney: ${hackney.style.fontFamily};
      }
    `}</style>
  );
}
